import HomePage from './pages/homePage/HomePage';

import './index.css';



const App = () => {
  return (
      <HomePage />
  );
}

export default App;