import { Link } from 'react-router-dom';

import img_project from './images/project.png';
import img_rent_calculator from './images/rent calculator.png';
import img_monte_carlo_simulator from './images/monte carlo simulator.png';

import "./ProjectsStyles.css";

const Projects = () => {
    return (
        <section className="standardized-maxwidth standardized-padding-LR standardized-padding-TopBottom standardized-background-color-02">

            <h1 className="standardized-font-andale-mono-h2 ">PROJECTS</h1>
            <div style={{ height: '10px' }}></div>

            <div className='container-projects'>

                <section className="div-project div-flex">
                    
                    <Link to="/projects/rentcalculator" >
                        <img className="project-image" src={img_rent_calculator} />
                    </Link>

                    <h1 className="font-andale-mono-h1">RENT CALCULATOR</h1>

                    <p className="project-text">How much money do you need to make to afford your apartment?</p>

                </section>

                <section className="div-project div-flex">
                    
                    <Link to="/projects/montecarlo" >
                        <img className="project-image" src={img_monte_carlo_simulator} />
                    </Link>

                    <h1 className="font-andale-mono-h1">MONTE CARLO SIMULATOR</h1>

                    <p className="project-text">Predict tomorrows stock price by simulating the past.</p>

                </section>
            </div>

        </section>
    );
}

export default Projects;