// =============================================================
// v0.03
// ===== HERE IS YOUR STUPID OPEN AND CLOSE ANIMATIONS (03):
// ===== notes: (2)
// =====    1. reference gemini ai: 'i have 2 javascript functions... i want to execute the second function only after the 1st has completed'
// =====    2. animation_01 executes for FIVE seconds, animation_02 executes for ONE second
// =============================================================

// ======================================
// ===== FUNCTION 01
// ======================================
const animation_01_open = async (element, idx) => {
    let parent = element

    // let parent = document.querySelector('.second-job-titles')
    let child = parent.children[idx]

    // step 1: show graphic
    child.setAttribute('class', 'is-visible');

    // step 2: start animation (fade open)
    let width = 0;
    let intervalId = setInterval(() => {
        width += 1;
        let str_width = `width: ${width}px; overflow: hidden;`;
        // console.log(str_width)
        parent.setAttribute('style', str_width);

        let max_width = 300
        if ( width >= max_width ) {
        clearInterval(intervalId);
        // console.log('finished');
        // console.log(parent.getAttribute('style'));
        }
    }, 1);

    await new Promise(resolve => setTimeout(resolve,5000));
    // console.log('Function 1 completed');
}

// ======================================
// ===== FUNCTION 02
// ======================================
const animation_02_close = async (element, idx) => {
    let parent = element

    // let parent = document.querySelector('.second-job-titles')
    let child = parent.children[idx]

    // step 1: show graphic
    child.setAttribute('class', 'is-visible');

    // step 2: start animation (fade open)
    let curr_width = 200;
    let intervalId = setInterval(() => {
        curr_width -= 1;
        let str_width = `width: ${curr_width}px; overflow: hidden;`;
        // console.log(str_width)
        parent.setAttribute('style', str_width);

        let min_width = 0
        if ( curr_width <= min_width ) {
        clearInterval(intervalId);
        // console.log('finished');
        // console.log(parent.getAttribute('style'));
        }
    }, 1);

    await new Promise(resolve => setTimeout(resolve, 1000));
    // console.log('Function 2 completed');
}

// ======================================
// ===== FUNCTION 03 (hide)
// ======================================
const hide_element = async (element, idx) => {
    let parent = element

    // ...
    let child = parent.children[idx]

    // step 1: hide graphic
    child.setAttribute('class', 'is-hidden');

    await new Promise(resolve => setTimeout(resolve, 1));
    // console.log('Element hidden');
}

// ======================================
// ===== FUNCTION 04 (MAIN)
// ======================================
const Main_NizorAnimations = async () => {

    let loop_count = 30;
    for ( let i=0; i < loop_count; i++ ) {
        try {
            // main fucntion
            let parent = document.querySelector('.second-job-titles')
            let loop_count_02 = parent.children.length
            for ( let j=0; j < loop_count_02; j++) {
                await animation_01_open(parent, j);
                await animation_02_close(parent, j);
                await hide_element(parent, j);
            }
        } catch (err) {
            // do nothing: if main function errors
            console.log('Caught nizor errors: ', err)
        }
        
    }
    
};

export default Main_NizorAnimations;