import { useState } from 'react';
import { Link } from 'react-router-dom';

import logo_rq from '../navbar/images/logo_rq_mini.png';



const Navbar02 = (props) => {

    const [clicked, setClick] = useState(false);

    const handleClick = () => {
        setClick(!clicked);
    }

    return (
        <nav id='nav02'>
            <Link to="/" style={{ textDecoration: 'none'}}>
                <img src={logo_rq} width="30px" height="30px" />
            </Link>
            
            <div id="mobile" onClick={handleClick}>
                    <i id="bar" className={clicked ? "fas fa-times":"fas fa-bars"} ></i>
            </div>

            <div id="sidebar" className={clicked ? "#sidebar active":"#sidebar"}>
                <ul id="navbar" className="font-opensans-semibold">
                    <Link to="/" style={{ textDecoration: 'none'}}>
                        <li><a>Home</a></li>
                    </Link>
                </ul>
            </div>
        </nav>
    );
}



export default Navbar02;