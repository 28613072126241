const TableSummaryStats = (props) => {

    // ##### utility functions ############################
    const formatUsd = (num) => {
        if (num==='') {
          return ''
        } else {
          num = parseFloat(num)
          return '$' + num.toFixed(2).toLocaleString()
        }
      }
    
    /////////////////////////////////////////////////////////////////////////
    ///// qc: good way to traverse thru POST return value
    // console.log('props.formData: ', props.formData);
    // console.log('props.formData.post_response: ', props.formData.post_response );
    // console.log('props.formData.post_response.monteCarlo_histogram: ', props.formData.post_response.monteCarlo_summaryStats );
    /////////////////////////////////////////////////////////////////////////

    const apiResponse = props.formData.post_response;
    // console.log('apiResponse: ', apiResponse);

    // console.log('apiResponse.length: ', apiResponse.length)
    // Error catching. If len = 0 then json is blank.
    if ( apiResponse.length !== 0 ) {
        // console.log('true block executed: ', apiResponse)

        // define headers
        const dataHeaders = ['stats_key_name', ' ']

        const trials = props.formData.post_response.monteCarlo_summaryStats.trials
        const mean = props.formData.post_response.monteCarlo_summaryStats.mean
        const median = props.formData.post_response.monteCarlo_summaryStats.median
        const min = props.formData.post_response.monteCarlo_summaryStats.min
        const max = props.formData.post_response.monteCarlo_summaryStats.max
        const st_dev = props.formData.post_response.monteCarlo_summaryStats.st_dev

        // console.log('trials: ', trials)
        // console.log('mean: ', mean)
        // console.log('median: ', median)
        // console.log('min: ', min)
        // console.log('max: ', max)
        // console.log('st_dev: ', st_dev)

        const rawDataRows = {
            'stats_key_name': ['trials', 'mean', 'median', 'min', 'max', 'st_dev'],
            'FORMATTED_stats_key_name': ['Trials', 'Mean', 'Median', 'Minimum', 'Maximum', 'Standard Deviation'],
            ' ': [ trials, mean, median, min, max, st_dev ]
        }
        // console.log('rawDataRows: ', rawDataRows)

        ///// build json ////////////////////////////////////////////////////////
        const dataRows = []

        // for every row
        let row_len = rawDataRows[ dataHeaders[0] ].length
        // console.log('row_len: ', row_len)

        for ( var i = 0; i < row_len; i++ ) {

            // for ea col
            var dict = {}
            var header_len = dataHeaders.length
            for ( var j = 0; j < header_len; j++ ) {
                if ( j === 0 ) {

                    // i want to display 'FORMATTED_stats_key_name'
                    dict[ dataHeaders[j] ] = rawDataRows[ 'FORMATTED_stats_key_name' ][i]

                } else {

                    dict[ dataHeaders[j] ] = rawDataRows[ dataHeaders[j] ][i]
                }
            }

            // commit row
            dataRows.push(dict)
        }

        // console.log('dataRows: ', dataRows)
        /////////////////////////////////////////////////////////////////////////


        //////////////////////////////////////////////////////
        /// module name:
        ///     RENDER rows + columns 
        /// execution notes:
        ///     has (2) functions:
        ///         1. render header
        ///         2. render rows
        //////////////////////////////////////////////////////

        // render headers


        // render rows
        const rows = dataRows.map( (x, i) => {

            // if statement => convert only $$$ columns
            // if statement => do not convert 1st row (aka trials)
            // returns each column 1-by-1
            var cols = dataHeaders.map( ea_dim => {
                if ( i === 0 ) {
                    if ( ea_dim === 'stats_key_name' ) {
    
                        return (
                            <td>{ x[ea_dim] }</td>
                        );
    
                    } else {
    
                        return (
                            <td>{ x[ea_dim] }</td>
                        );
    
                    }

                } else {
                    
                    if ( ea_dim === 'stats_key_name' ) {
    
                        return (
                            <td>{ x[ea_dim] }</td>
                        );
    
                    } else {
    
                        return (
                            <td>{ formatUsd( x[ea_dim] ) }</td>
                        );
    
                    }

                }    
            });

            // returns each ROW 1-by-1
            return (
                <tr key={i}>
                    {cols}
                </tr>
            );

        });

        // console.log('rows: ', rows)

        return (
            <section>

                <table className='table'>

                    <thead> 
                        <tr>
                            <th>Statistic</th>
                            <th> </th>
                        </tr>
                    </thead>

                    <tbody> { rows } </tbody>

                </table>

            </section>
        );

        
    // } else {
    //     // console.log('false block executed: ', apiResponse)
    };
}

export default TableSummaryStats;