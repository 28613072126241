import './AboutStyles.css';

const About = () => {
    return (
        <section className='standardized-maxwidth standardized-padding-LR standardized-padding-TopBottom'>

            <h1 className='standardized-font-andale-mono-h2'>ABOUT</h1>
            
            <p className='homepage-text'>
                I'm a <span className='highlight'>DATA ENGINEER</span> specalizing in <span className='highlight'>Amazon Web Services (AWS)</span> technologies. <br/>
                <br/>
                I created this website to showcase my projects. <br/>
                <br/>
                Feel free to connect with me! <br/>
            </p>
            
        </section>
    );
}

export default About;