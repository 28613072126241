import Navbar02 from '../../components/navbar02/Navbar02';
import Footer from '../../components/footer/Footer';

import './NotFoundStyles.css';

import you_look_lost from './images/you_look_lost.png';

const NotFound = () => {
    return (
        <section>
            <Navbar02 />
            <div style={{ height: '57px' }}></div>

            <div style={{ height: '57px' }}></div>
            <img src={you_look_lost} style={{ 'width': '100%' }}/>

            <div style={{ height: '1000px' }}></div>
            <Footer />
        </section>
    );
}

export default NotFound;