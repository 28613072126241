import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import App from './App';
import NotFound from './pages/notFoundPage/NotFound';
import RentCalculator1Expose from './pages/projects/rentCalculator/RentCalculator1Expose';
import MonteCarlo1Expose from './pages/projects/monteCarlo/MonteCarlo1Expose';
// import MonteCarlo from './pages/projects/monteCarlo/MonteCarlo';

import './index.css';



const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <NotFound /> 
  },
  {
    path: '/projects/rentcalculator',
    element: <RentCalculator1Expose />
  },
  {
    path: '/projects/montecarlo',
    element: <MonteCarlo1Expose />
  }
])


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
    <RouterProvider router={router} />
  // </React.StrictMode>
);