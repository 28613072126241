import { useState } from "react";
import logo_rq from "./images/logo_rq_mini.png"

import "./NavbarStyles.css";



const Navbar = (props) => {

    const [clicked, setClick] = useState(false);

    const handleClick = () => {
        setClick(!clicked);
    }

    return (
        <nav>
            <img src={logo_rq} width="30px" height="30px" />
            
            <div id="mobile" onClick={handleClick}>
                <i id="bar" className={clicked ? "fas fa-times":"fas fa-bars"} ></i>
            </div>

            <div id="sidebar" className={clicked ? "#sidebar active":"#sidebar"}>
                <ul id="navbar" className="font-opensans-semibold">
                    <li><a onClick={props.handleScroll_Landing}>Home</a></li>
                    <li><a onClick={props.handleScroll_About}>About Me</a></li>
                    <li><a onClick={props.handleScroll_Projects}>Projects</a></li>
                    <li><a onClick={props.handleScroll_Contact}>Contact</a></li>
                </ul>
            </div>
        </nav>
    );
}



export default Navbar;