// note: name attribute is required if you want to see it in the email

import Linkedin from './images/linkedin.png';
import { useState } from 'react';

import './ContactMeStyles.css';

const ContactMe = () => {
    const [result, setResult] = useState("");

    const onSubmit = async (event) => {
      event.preventDefault();
      setResult("Sending....");
      const formData = new FormData(event.target);
  
      formData.append("access_key", "3606b61a-97aa-4785-8ea2-45b1011332e8");
  
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
      });
  
      const data = await response.json();
  
      if (data.success) {
        setResult("Email is on its way!");
        event.target.reset();
      } else {
        console.log("Error", data);
        setResult(data.message);
      }
    };

    return (
        <section className='standardized-maxwidth standardized-padding-LR standardized-padding-TopBottom'>

            <h1 className="standardized-font-andale-mono-h2">CONTACT</h1>
            <div style={{ height: '10px' }}></div>
            
            <div id="contact-top">
                <div id="contact-left">
                    {/* <h1 id="font-obitron-black-02">LET'S CONNECT</h1>
                    <div style={{ height: '3px' }}></div> */}
                    <p id="homepage-text" className="homepage-text">Have a question or want to collaborate? Send me a message here!</p>
                </div>

                <div id="contact-right">
                    <form onSubmit={onSubmit}>
                        <div id="row-01">
                            <input name="name" type="text" className="input-formatting" placeholder="First*" required></input>

                            <select name="ticket" className="input-formatting" required>
                                <option value='' disabled selected            >Reason for Contact:</option>
                                <option value="leave a comment"               >leave a comment</option>
                                <option value="consulting - data engineering" >consulting - data engineering</option>
                                <option value="consulting - web app"          >consulting - web app</option>
                                <option value="consulting - aws"              >consulting - aws</option>
                            </select>
                        </div>

                        <div id="row-02">
                            <input name="email" type="email" className="input-formatting" placeholder="E-mail*" style={{ "width":"100%" }} required></input>
                        </div>

                        <div id="row-04">
                            <textarea name="message" type="text" className="input-formatting" id="msginput" placeholder="Message*" required></textarea>
                        </div>

                        <div>
                            <button id="btn-send-it-to-the-moon">SEND IT!</button>
                        </div>

                    </form>
                    <span id="email-msg-span">{result}</span>

                </div>
            </div>

            <div id="follow-me">
                <div>
                    <p className="homepage-text">Follow me on</p>
                </div>

                <div style={{ height: '10px' }}></div>

                <div id="icons">
                    <a href="https://www.linkedin.com/in/reubenquinto">
                        <img src={Linkedin} />
                    </a>
                </div>
                
            </div>

        </section>
    );
}

export default ContactMe;