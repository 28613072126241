import { useRef } from 'react';

import Navbar from '../../components/navbar/Navbar';
import Landing from './components/landing/Landing';
import About from './components/about/About';
import Projects from './components/projects/Projects';
import ContactMe from './components/contactMe/ContactMe';
import Footer from '../../components/footer/Footer';

import './HomePageStyles.css';



const HomePage = () => {

  // scroll to LANDING section
  const scrollRef_Landing = useRef(null);

  const handleScroll_Landing = () => {
    scrollRef_Landing.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // scroll to ABOUT section
  const scrollRef_About = useRef(null);

  const handleScroll_About = () => {
    scrollRef_About.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // scroll to PROJECTS section
  const scrollRef_Projects = useRef(null);

  const handleScroll_Projects = () => {
    scrollRef_Projects.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // scroll to CONTACT section
  const scrollRef_Contact = useRef(null);

  const handleScroll_Contact = () => {
    scrollRef_Contact.current?.scrollIntoView({ behavior: 'smooth' });
  };



    return (
        <>    
            <Navbar
                handleScroll_Landing={handleScroll_Landing}
                handleScroll_About={handleScroll_About}
                handleScroll_Projects={handleScroll_Projects}
                handleScroll_Contact={handleScroll_Contact}
            />
    
            <div style={{ height: '57px' }}></div>
    
            <div className="element" ref={scrollRef_Landing}>
                <Landing />
            </div>
    
            <div className="element" ref={scrollRef_About}>
                <About />
            </div>
    
            <div className="element" ref={scrollRef_Projects} >
                <Projects />
            </div>
    
            <div className="element" ref={scrollRef_Contact} >
                <ContactMe />
            </div>

            <div>| v1.06 | 2024-09-16 |</div>
    
            <div>
                <Footer />
            </div>  
      </>
    );
}

export default HomePage;