// plotly attributes: https://plotly.com/javascript/reference/

import Plot from 'react-plotly.js'
import { withSize } from 'react-sizeme'; // <--------------- REQUIRED

import './styles/GraphTimeSeries.css';



const GraphTimeSeries = ( props ) => { // <--------------- REQUIRED

    /////////////////////////////////////////////////////////
    ///// function: getNextBusinessDay
    /////////////////////////////////////////////////////////

    function getNextBusinessDay(dateList) {
        // Convert date strings to Date objects
        const dateObjects = dateList.map(dateString => new Date(dateString));
      
        // Find the maximum date in the list of Date objects
        const maxDate = new Date(Math.max(...dateObjects));
      
        // Check if the maximum date is a weekend (Saturday or Sunday)
        if (maxDate.getDay() === 0 || maxDate.getDay() === 6) {
          // If weekend, add 2 days to skip to the next business day (Monday)
          maxDate.setDate(maxDate.getDate() + 2);
        } else {
          // If weekday, simply add 1 day
          maxDate.setDate(maxDate.getDate() + 1);
        }
      
        // Return the next business day in the same format as extendDateList
        return maxDate.toISOString().slice(0, 10);
      }

    /////////////////////////////////////////////////////////
    ///// set: layout
    /////////////////////////////////////////////////////////

    let layout = {
        title: ' Closing Prices',
        // width: 1200,
        // height: 600,
        autosize: true,

        paper_bgcolor: 'rgba(0,0,0,0)', //'#060b1f',
        plot_bgcolor: 'rgba(0,0,0,0)',
        
        font: {
            color: '#ffffff',
            // size: 20
        },

        xaxis: {
            // title: 'x-axis',
            // range: [0, 20]
            autorage: true,
            showgrid: true,

            // set grid styles
            gridcolor: '#434969',
            griddash: '1px',

            // set x=0 style
            showline: true,
            linewidth: 1,
            linecolor: '#253175'
        },
        yaxis: {
            // title: 'y-axis',
            // range: [10, 30],

            // set grid styles
            gridcolor: '#434969',
            griddash: '1px',

            // set y=0 style
            showline: true,
            linewidth: 1,
            linecolor: '#253175'
        },
        legend: {
            // orientation: 'h',
            // y: 1.0, // moves legend above plot area
            // yanchor: 'bottom', // anchors the bottom of the legend at the y-position
            // x: 0.5, // centers the legend horizontally
            // xanchor: 'center' // anchors the center of the legend to the x-position
        },
        margin: {
            l: 40, //40
            r: 0,
            t: 40,
            // b: 40
        },
        showlegend: false
    }

    // // @media solution: resize graph text size
    // console.log('props.size.width: ', props.size.width);
    if ( props.size.width <= 400 ) {
        layout.font.size = 12
    } else {
        layout.font.size = 20
    }

    /////////////////////////////////////////////////////////

    let data_x = '';
    let data_y = '';    

    let data = []

    // REQUIRED: lesson learned. this page is loaded BEFORE post request is called
    //      resolution: if statement (acts as a catch / conditional rendering
    if ( props.formData.post_response !== '' ) {
        /////////////////////////////////////////////////////////////////////////
        ///// qc: good way to traverse thru POST return value
        // console.log('On Timeseries Page - props: ', props);
        // console.log('On Timeseries Page - props.formData: ', props.formData);
        // console.log('On Timeseries Page - props.user_selected_stock: ', props.user_selected_stock);
        // console.log('On Timeseries Page - props.user_selected_stock.stock_selected: ', props.user_selected_stock.stock_selected);
        // console.log('On Timeseries Page: ', props.formData.post_response );
        // console.log('On Timeseries Page: ', props.formData.post_response.stock_data_closing );
        // console.log('On Timeseries Page: ', props.formData.post_response.stock_data_closing.date );
        // console.log('On Timeseries Page: ', props.formData.post_response.stock_data_closing.close );

        // ///// confidence levels (user input side)
        // console.log('On Timeseries Page: props.userInputConfidenceLevel '
        //                 , props.userInputConfidenceLevel)    

        // ///// confidence levels (flask side)
        // console.log('On Timeseries Page: formData.post_response '
        //                 , props.formData.post_response)

        // console.log('On Timeseries Page: props.formData.post_response.monteCarlo_confidenceLevels '
        //                 , props.formData.post_response.monteCarlo_confidenceLevels )

        // console.log('On Timeseries Page: props.formData.post_response.monteCarlo_confidenceLevels[99] '
        //                 , props.formData.post_response.monteCarlo_confidenceLevels[99] )
    
        // console.log('On Timeseries Page: props.formData.post_response.monteCarlo_confidenceLevels[99].lower_bound '
        //                 , props.formData.post_response.monteCarlo_confidenceLevels[99].lower_bound )
    
        // console.log('props.size: ', props.size);
        /////////////////////////////////////////////////////////////////////////

        /////////////////////////////////////////////////////////
        ///// function: extending date array
        /////////////////////////////////////////////////////////
        function extendDateList(dateList, extensionPercentage) {
            // Convert date strings to Date objects
            const dateObjects = dateList.map(dateString => new Date(dateString));

            // Calculate the extension count
            const extensionCount = Math.ceil(dateObjects.length * extensionPercentage );

            // Calculate the average interval between dates
            const averageInterval = (dateObjects[dateObjects.length - 1] - dateObjects[0]) / (dateObjects.length - 1);

            // Extend the list
            for (let i = 0; i < extensionCount; i++) {
                const lastDate = dateObjects[dateObjects.length - 1];
                const newDate = new Date(lastDate.getTime() + averageInterval);
                dateObjects.push(newDate);
            }

            // Convert date objects back to strings and return the extended list
            return dateObjects.map(date => date.toISOString().slice(0, 10));
            }

        /////////////////////////////////////////////////////////
        ///// create closing prices
        /////////////////////////////////////////////////////////

        data_x = props.formData.post_response.stock_data_closing.date;
        // console.log('data_x before:', data_x.length)
        
        let buffer = .10
        data_x = extendDateList(data_x, buffer)
        // console.log('data_x after:', data_x.length)

        data_y = props.formData.post_response.stock_data_closing.close;
        console.log('data_y: ', data_y)

        let graph_01 = {
                            // closing prices
                            x: data_x,
                            y: data_y,
                            mode: 'lines',
                            name: 'closing prices',
                            marker: {color: '#16b3d8'}
                       };

        data.push(graph_01);

        /////////////////////////////////////////////////////////
        ///// create dynamic chart name
        /////////////////////////////////////////////////////////

        var chart_name_timeseries = props.formData.post_response.user_selected_stock.stock_selected
        layout.title = chart_name_timeseries + ' Closing Prices'
        // console.log('props: ', props)
        // console.log('props.formData: ', props.formData)
        // console.log('props.formData.post_response: ', props.formData.post_response)
        // console.log('props.formData.post_response.user_selected_stock: ', props.formData.post_response.user_selected_stock)
        // console.log('props.formData.post_response.user_selected_stock.stock_selected: ', props.formData.post_response.user_selected_stock.stock_selected)

    }

    const userInputConfidenceLevel = props.userInputConfidenceLevel
    if (userInputConfidenceLevel !== '' ) {

        /////////////////////////////////////////////////////////
        ///// function: create a list with n elements.
        /////////////////////////////////////////////////////////
        const createRepeatingList = ( repeat, num_times ) => {
            const ls = []
            for ( let i = 0; i < num_times ; i++ ) {
                ls.push( repeat )
            }
            return ls;
        }
        
        /////////////////////////////////////////////////////////
        ///// create upper bounds
        /////////////////////////////////////////////////////////
        let apiData_upper = props.formData.post_response.monteCarlo_confidenceLevels[ userInputConfidenceLevel ].upper_bound
        // console.log('apiData_upper: ', apiData_upper)

        // remember... this is just 1 number
        apiData_upper = apiData_upper.toFixed(2)
        // console.log('apiData_upper: ', apiData_upper)

        // multiply x times for plotting purposes
        let data_upper = createRepeatingList( apiData_upper, data_x.length )
        // console.log('data_upper: ', data_upper)

        let graph_02 = {
                            // upper bound
                            x: data_x,
                            y: data_upper,
                            mode: 'lines',
                            marker: {color: 'red'},
                            name: 'upper bound',
                            line: {
                                dash: 'dash'
                            }
                       };

        data.push(graph_02);

        /////////////////////////////////////////////////////////
        ///// create lower bounds
        /////////////////////////////////////////////////////////
        let apiData_lower = props.formData.post_response.monteCarlo_confidenceLevels[ userInputConfidenceLevel ].lower_bound
        
        // remember... this is just 1 number
        apiData_lower = apiData_lower.toFixed(2) 
        // console.log('apiData_lower: ', apiData_lower)

        // multiply x times for plotting purposes
        // lesson learned: dont double declare variables
        let data_lower = createRepeatingList( apiData_lower, data_x.length )
        // console.log('data_lower: ', data_lower)
        
        let graph_03 = {
                            // lower bound
                            x: data_x,
                            y: data_lower,
                            mode: 'lines',
                            marker: {color: 'red'},
                            name: 'lower bound',
                            line: {
                                dash: 'dash'
                            }
                       };

        data.push(graph_03);

        /////////////////////////////////////////////////////////
        ///// GRAPH TYPE: line with no markers - D+1
        /////////////////////////////////////////////////////////
        let apiData_pe = props.formData.post_response.monteCarlo_confidenceLevels[ userInputConfidenceLevel ].point_estimate
        // console.log('apiData_pe: ', apiData_pe)

        apiData_pe = apiData_pe.toFixed(2)
        // console.log('apiData_pe: ', apiData_pe)

        // get the next business day
        let next_business_day = getNextBusinessDay(props.formData.post_response.stock_data_closing.date)
        console.log('data_x: ', data_x)
        console.log('next_business_day: ', next_business_day)

        let graph_04 = {
                            // lower bound
                            x: [ next_business_day ],
                            y: [ apiData_pe ],
                            // type: 'scatter',
                            name: 'expected value',
                            mode: 'markers',
                            marker: {
                                color: 'red',
                                symbol: 'circle-open',
                                size: 10,
                                line: {
                                    width: 1
                                }
                            },
                       }

        data.push(graph_04);

    }

    // console.log('props.size: ', props.size)

    return(
        <div>
            <Plot
                data={data}
                layout={layout}
                useResizeHandler={true}
                style={{width: "100%", height: "100%"}}
                config={{
                    displayModeBar: false,
                }}
            />
        </div>
    );
}

export default withSize()(GraphTimeSeries); // <--------------- REQUIRED: withSize()(<MyComponent>)