import { useEffect } from 'react';

import Main_NizorAnimations from './components/nizorAnimations/NizorAnimations';

import './LandingStyles.css';
import './components/nizorAnimations/nizorAnimationsStyles.css';

import amzn_headshot from './images/amzn-headshot-v02.png'

const Landing = () => {

    useEffect( () => {
        Main_NizorAnimations();
    }, [])

    return (

        /* lessons learned: you can have multiple classNames but not multiple id's */
        <section id='standardized-seattle-background' className='standardized-maxwidth standardized-padding-LR div-landing'>

            <div id='div-level01-firstname-lastname-jobtitles'>
                
                <div id='div-level02-div-top'>
                    <h1 className='font-obitron-black'>REUBEN QUINTO</h1>
                    <div style={{ height: '3px' }}></div>
                    <h5 className='font-Andale-Mono'>DATA ENGINEER</h5>
                </div>

                <div id='div-level02-div-bottom'>
                    <div id='div-level03-nizor' class="font-Andale-Mono second-job-titles">
                        <div class="is-visible">Full Stack Engineer</div>
                        <div class="is-hidden">Web App Developer</div>
                        <div class="is-hidden">AWS Solutions Architect</div>
                        <div class="is-hidden">Analyst</div>
                    </div>
                </div>
            </div>

            <div id='div-level01-amzn-headshot'>
                <img id='img-amzn-headshot' src={amzn_headshot} />
            </div>

        </section>
    );
}

export default Landing;