import './styles/HtmlTableStyles.css';



/////////////////////////////////////////////////////////////////////////
///// lessons learned:
/////   procedures:
/////       1. ask yourself what datapoints you need first.
/////       2. import/declare data (via props)
/////       3. build graph
/////       4. style later
/////       5. console log frequently, console log often
/////////////////////////////////////////////////////////////////////////



const TablePercentiles = (props) => {

    // ##### utility functions ############################
    const formatUsd = (num) => {
        if (num==='') {
          return ''
        } else {
          num = parseFloat(num)
          return '$' + num.toFixed(2).toLocaleString()
        }
      }
    
    /////////////////////////////////////////////////////////////////////////
    ///// qc: good way to traverse thru POST return value
    // console.log('props.formData: ', props.formData);
    // console.log('props.formData.post_response: ', props.formData.post_response );
    // console.log('props.formData.post_response.monteCarlo_histogram: ', props.formData.post_response.monteCarlo_histogram );
    /////////////////////////////////////////////////////////////////////////

    const apiResponse = props.formData.post_response;
    // console.log('apiResponse: ', apiResponse);

    // console.log('apiResponse.length: ', apiResponse.length)
    // Error catching. If len = 0 then json is blank.
    if ( apiResponse.length !== 0 ) {
        console.log('true block executed: ', apiResponse)

        // define headers
        const dataHeaders = ['Percentiles', 'Forcast Values']

        const rawDataRows = {
            'Percentiles': props.formData.post_response.monteCarlo_percentiles.buckets,
            'Forcast Values': props.formData.post_response.monteCarlo_percentiles.percentiles
        }
        console.log('rawDataRows: ', rawDataRows)

        ///// build json ////////////////////////////////////////////////////////
        const dataRows = []

        // for every row
        let row_len = rawDataRows[ dataHeaders[0] ].length
        console.log('row_len: ', row_len)

        for ( var i = 0; i < row_len; i++ ) {

            // for ea col
            var dict = {}
            var header_len = dataHeaders.length
            for ( var j = 0; j < header_len; j++ ) {
                dict[ dataHeaders[j] ] = rawDataRows[ dataHeaders[j] ][i]
            }

            // commit row
            dataRows.push(dict)
        }

        // console.log('dataRows: ', dataRows)
        /////////////////////////////////////////////////////////////////////////


        //////////////////////////////////////////////////////
        /// module name:
        ///     RENDER rows + columns 
        /// execution notes:
        ///     has (2) functions:
        ///         1. render header
        ///         2. render rows
        //////////////////////////////////////////////////////

        // render headers
        const headers = dataHeaders.map( ea_dim => {
            return (
                <th>{ea_dim}</th>
            );
        });
        console.log('headers: ', headers)

        // render rows
        const rows = dataRows.map( (x, i) => {

            // returns each column 1-by-1
            var cols = dataHeaders.map( ea_dim => {
                if ( ea_dim === 'Percentiles') {

                    return (
                        <td>{ x[ea_dim] + "%" }</td>
                    );

                } else {

                    return (
                        <td>{ formatUsd( x[ea_dim] ) }</td>
                    );

                }
            });

            // returns each ROW 1-by-1
            return (
                <tr key={i}>
                    {cols}
                </tr>
            );

        });

        console.log('rows: ', rows)

        return (
            <section>

                <table className='table'>

                    <thead ><tr> {headers} </tr></thead>

                    <tbody> {rows} </tbody>

                </table>

            </section>
        );

        
    } else {
        // console.log('false block executed: ', apiResponse)
    };
}

export default TablePercentiles;