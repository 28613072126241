import Plot from 'react-plotly.js'
import { withSize } from 'react-sizeme'; // <--------------- REQUIRED

import './styles/GraphTimeSeries.css'; // <------------------------ REQUIRED



const GraphHistogram = ( props ) => {
    /////////////////////////////////////////////////////////////////////////
    // ///// qc: good way to traverse thru POST return value
    // console.log('On Timeseries Page: ', props.formData);
    // console.log('On Timeseries Page: ', props.formData.post_response );
    // console.log('On Timeseries Page: ', props.formData.post_response.stock_data_closing );
    // console.log('On Timeseries Page: ', props.formData.post_response.stock_data_closing.date );
    // console.log('On Timeseries Page: ', props.formData.post_response.stock_data_closing.close );

    // ///// confidence levels (user input side)
    // console.log('On Timeseries Page: props.userInputConfidenceLevel '
    //                 , props.userInputConfidenceLevel)    

    ///// confidence levels (flask side)
    // console.log('On Timeseries Page: formData.post_response '
    //                 , props.formData.post_response)

    // console.log('On Timeseries Page: formData.post_response.monteCarlo_histogram: '
    //                 , props.formData.post_response.monteCarlo_histogram)

    // console.log('On Timeseries Page: props.formData.post_response.monteCarlo_confidenceLevels '
    //                 , props.formData.post_response.monteCarlo_confidenceLevels )

    // console.log('On Timeseries Page: props.formData.post_response.monteCarlo_confidenceLevels[99] '
    //                 , props.formData.post_response.monteCarlo_confidenceLevels[99] )

    // console.log('On Timeseries Page: props.formData.post_response.monteCarlo_confidenceLevels[99].lower_bound '
    //                 , props.formData.post_response.monteCarlo_confidenceLevels[99].lower_bound )

    /////////////////////////////////////////////////////////////////////////

    const apiResponse = props.formData.post_response;
    // console.log('apiResponse.length: ', apiResponse.length)

    const data = [];
    let lower_bound = ''
    let upper_bound = ''
    let histogram_max = ''
    
    /////////////////////////////////////////////////////////
    ///// set: layout
    /////////////////////////////////////////////////////////

    let layout = {
        title: 'Distribution of Closing Prices',
        autosize: true, // <------------------------ REQUIRED

        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',

        font: {
            color: '#ffffff',
            // size: 20
        },
        xaxis: {
            // title: 'x-axis',
            // range: [0, 20]
            autorage: true,
            showgrid: true,

            // set grid styles
            gridcolor: '#434969',
            griddash: '1px',

            // set x=0 style
            showline: true,
            linewidth: 1,
            linecolor: '#253175'
        },
        yaxis: {
            // title: 'y-axis',
            // range: [10, 30],

            // set grid styles
            gridcolor: '#434969',
            griddash: '1px',

            // set y=0 style
            showline: true,
            linewidth: 1,
            linecolor: '#253175'
        },
        margin: {
            l: 80,
            r: 0,
            // t: 40,
            // b: 40
        },
        shapes: [
            {
                type: 'line',
                x0: lower_bound,
                x1: lower_bound,
                y0: 0,
                y1: histogram_max,
                line: {
                    color: 'red',
                    width: 2,
                    dash: 'dash'
                }
            },
            {
                type: 'line',
                x0: upper_bound,
                x1: upper_bound,
                y0: 0,
                y1: histogram_max,
                line: {
                    color: 'red',
                    width: 2,
                    dash: 'dash'
                }
            }
        ]
    }

    // // @media solution: resize graph text size
    // console.log('props.size.width: ', props.size.width);
    if ( props.size.width <= 400 ) {
        layout.font.size = 12
        layout.margin.l = 40
        layout.margin.r = 0
    } else {
        layout.font.size = 20
        layout.margin.r = 80
        layout.margin.r = 0
    }

    // Error catching. If len = 0 then json is blank.
    if ( apiResponse.length !== 0 ) {
        // console.log('testing: apiRespose.constructor is NOT blank', apiResponse)

        // api provided histogram
        let monteCarlo_histogram = props.formData.post_response.monteCarlo_histogram
        // console.log('monteCarlo_histogram: ', monteCarlo_histogram)

        // api provided confidence levels
        let monteCarlo_confidenceLevels = props.formData.post_response.monteCarlo_confidenceLevels
        // console.log('monteCarlo_confidenceLevels: ', monteCarlo_confidenceLevels)

        // qc: check x & y
        // console.log('monteCarlo_histogram.bins: ', monteCarlo_histogram.bins)
        // console.log('monteCarlo_histogram.freq: ', monteCarlo_histogram.freq)

        // // creating the height of the confidence intervals
        // note: ... is spread operator. It's a powerful tool that allows you to expand an iterable (like an array) into individual elements.
        // console.log('testing here: ', Math.max(...Object.values( monteCarlo_histogram.freq ) ));
        histogram_max = Math.max(...Object.values( monteCarlo_histogram.freq ));
        console.log('histogram_max: ', histogram_max);

        let trace01_bars = {
            x: monteCarlo_histogram.bins,
            y: monteCarlo_histogram.freq,
            type: 'bar',
            // width: 22, // Adjust the bar width as needed
            // bargap: -1, // doesnt work
            marker: {
                line: {
                    color: '#2ddaff', //#2ddaff, #3383b5
                    width: 1
                }
            }
        };

        data.push(trace01_bars)

        // Error catching. If len = 0 then json is blank.
        let userInput_confidenceLevels = props.userInputConfidenceLevel;
        // console.log('userInput_confidenceLevels: ', userInput_confidenceLevels)
        if ( userInput_confidenceLevels.length !== 0 ) {
            // console.log('testing: userInput_confidenceLevels is NOT blank', userInput_confidenceLevels)

            lower_bound = monteCarlo_confidenceLevels[ userInput_confidenceLevels ].lower_bound
            // console.log('lower_bound: ', lower_bound)
            upper_bound = monteCarlo_confidenceLevels[ userInput_confidenceLevels ].upper_bound
            // console.log('upper_bound: ', upper_bound)

            // bar color logic
            trace01_bars.marker.color = trace01_bars.x.map( (x) => {
                // return x
                return x >= lower_bound && x <= upper_bound ? 'rgba(97, 168, 195, 1)' : 'rgba(128, 128, 128,.2)'
            })
            // console.log('trace01_bars.marker.color: ', trace01_bars.marker.color)

            /////////////////////////////////////////////////////////
            ///// set: layout
            /////////////////////////////////////////////////////////

            layout.shapes[0].x0 = lower_bound
            layout.shapes[0].x1 = lower_bound
            layout.shapes[0].y1 = histogram_max

            layout.shapes[1].x0 = upper_bound
            layout.shapes[1].x1 = upper_bound
            layout.shapes[1].y1 = histogram_max

        } else {
            // console.log('testing: userInput_confidenceLevels is blank', userInput_confidenceLevels)
        }

    } else {
        // console.log('testing: apiRespose.constructor is blank', apiResponse)
    }



    return (
        <>
            <div className='test'>
                <Plot
                    data={data}
                    layout={layout}
                    config={{
                        displayModeBar: false
                    }}
                    useResizeHandler={true} // <---------------- REQUIRED
                    style={{width: "100%", height: "100%"}} // <---------------- REQUIRED
                />
            </div>
        </>
    );
};



export default withSize()(GraphHistogram); // <--------------- REQUIRED: withSize()(<MyComponent>)